<template>
  <div class="article-wrapper">
    <div
      v-if="isFindArticle && article.title"
    >
      <div class="article-navs">
        <div>{{ $t('articleNavs') }}</div>
      </div>
      <div class="article-main">
        <div class="article-title">
          <div>{{ article.title }}</div>
          <div>{{ article.time }}</div>
        </div>
        <div class="article-content">
          <div
            v-html="article.editor_html"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="no-article"
      v-if="isFindArticle && !article.title"
    >
      <div>未找到文章</div>
    </div>
  </div>
</template>

<script>
import { showMessage, goGet, formatTime } from '@/utils'
export default {
  name: 'Article',
  metaInfo() {
    return {
      title: this.$t('articleTitle')
    }
  },
  data() {
    return {
      isFindArticle: false,
      article: {}
    }
  },
  mounted() {
    const { id } = this.$route.params
    id ? this.getArticle(id) : showMessage('未找到对应的文章', 'error')
  },
  methods: {
    getArticle(id) {
      goGet('/ewinlight/article/find', {id: id}).then(res => {
        this.isFindArticle = true
        if (res.code === 200) {
          this.article = res.data
          console.log(res.data.show_time, 'res.data.show_time')
          this.article.time = formatTime('format', res.data.show_time)
          const reg = new RegExp('http://192.168.2.245:1500','g')
          this.article.editor_html = res.data.editor_html.replace(reg, process.env.VUE_APP_API)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-wrapper {
  width: 100%;
  min-height: calc(100vh);
  overflow: hidden;
  .article-navs {
    width: 100%;
    height: 70px;
    background: rgba(245, 246, 247, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    &>div {
      max-width: 1200px;
      width: 100%;
    }
  }
  .article-main {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .article-title {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 230px;
      border-bottom: 1px solid rgba(235, 235, 235, 1);
      & > div:nth-child(1) {
        color: rgba(51, 51, 51, 1);
        font-size: 36px;
        font-weight: bold;
        line-height: 50px;
      }
      & > div:nth-child(2) {
        font-size: 18px;
        color: rgba(153, 153, 153, 1);
        margin-top: 30px;
      }
    }
    .article-content {
      padding: 80px 0;
      ::v-deep .w-e-content-container {
        font-size: 18px;
        p {
          color: rgba(51, 51, 51, 1);
          font-size: 18px;
          margin: 40px 0;
          line-height: 32px;
          img {
            max-width: 935px;
          }
        }
      }
    }
  }
  .no-article {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 477px);
  }
}
</style>